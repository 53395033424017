export const useCreditCalculator = (amount, lineItems, isBill = false) => {
    const updateKey = ref(0)
    const rc = ref(amount)
    const ca = ref(0)

    const creditedAmount = computed(() => {
        return lineItems.reduce((acc, item) => {
            return acc + item.credit_amount
        }, 0)
    })

    const remainingCredit = computed(() => {
        return amount - creditedAmount.value
    })

    const onChangeAmount = (event, item) => {
        const value = +event.target.unmasked
        const unpaidAmount = isBill
            ? item.unpaid_amount
            : item.sale_document.unpaid_amount
        const max = unpaidAmount <= rc.value ? unpaidAmount : rc.value
        if (!value) {
            item.credit_amount = 0
            ca.value = creditedAmount.value
            rc.value = remainingCredit.value
            updateKey.value++
            return
        }

        if (value > max) {
            if (!rc.value && ca.value === item.credit_amount) {
                updateKey.value++
                return
            }

            item.credit_amount = max
            ca.value += max
            rc.value -= max
            updateKey.value++
            return
        }

        item.credit_amount = !value ? 0 : value
        ca.value += item.credit_amount
        rc.value -= item.credit_amount
        updateKey.value++
    }

    return {
        updateKey,
        creditedAmount,
        remainingCredit,
        onChangeAmount,
    }
}
